<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-form class="row" @submit="saveGift" @reset="onReset">

              <div class="col-md-12">
                <div class="form-inline ">
                  <input  id="show-bn-cms" class="form-control" type="checkbox" :true-value="1" :false-value="0" value="1" v-model="form.status">
                  <label for="show-bn-cms"  style=" margin-left: 10px">Hiển thị </label>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group" >
                  <label for="input">Thông tin giải thưởng</label>

                  <table class="table ">
                    <thead>
                    <tr style="font-size: 13px;">
                      <th scope="2" class="border-top-0">Chọn Icon(*)</th>
                      <th scope="4" class="border-top-0">Tiêu đề (*)</th>
                      <th scope="3" class="border-top-0">Mô tả (*)</th>
                      <th scope="2" class="border-top-0">Vị Trí (*)</th>
                      <th scope="1" class="border-top-0">Tác vụ</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in configs" :key="index">

                      <td>
                        <div style="display: flex" >
                          <div style="height: 30px; margin-right: 20px" v-show="item.icon.length >0">
                            <img
                                style="width: 38px; height: 38px"
                                :src="item.icon"
                                alt=""
                            />
                          </div>
                          <b-form-file
                              @change="onImageChange($event,index)"
                              :required="item.icon.length > 0?false:true"
                              placeholder="chọn ảnh icon"
                              accept="image/*"
                              type="file"
                          ></b-form-file>
                        </div>
                      </td>
                      <td>
                        <input class="form-control" required v-model="item.title">
                      </td>
                      <td>
                        <input class="form-control" required v-model="item.desc">
                      </td>
                      <td>
                        <input class="form-control" min="0" required v-model="item.position" type="number">
                      </td>

                      <td>
                        <button type="button" class="btn btn-danger btn-sm " @click="removeRuleItem(index)">
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div  class="btn btn-sm btn-primary" @click="addRule">Thêm thông tin</div>
                </div>

              </div>



              <div class="col-md-12">
                <b-button type="submit" variant="primary">{{
                    is_edit ? "Cập nhập" : "Xác nhận"
                  }}</b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
const CampaignRepository = RepositoryFactory.get("Campaign");
const WalletRunRepository = RepositoryFactory.get("WalletRun");

export default {
  name: "FormCategory",
  mixins: [Common],
  components: {
  },
  data() {
    return {
      form: {
        status: 1,
        data_gift: '',
      },
      configs:[
        {
          icon:'',
          title:'',
          desc:'',
          position:'',
        }
      ],
      is_edit: false,
    };

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Cơ cấu giải thưởng " },
    ]);
  },
  methods: {
    removeRuleItem (index) {
      let that = this;
      this.configs.forEach((item, i) => {
        if (i == index) {
          that.configs.splice(i, 1);
        }
      });
    },
    addRule () {
      this.configs.push({
        icon:'',
        title:'',
        desc:'',
        position:'',
      });
    },
    onImageChange(e,position) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      let fileSize = files[0].size / 1024 / 1024; // in MiB
      if (fileSize> 2){
        this.$notify({
          group: "foo",
          type: "error",
          title:"Chọn ảnh không vượt quá 2MB",
        });
        return false;
      }
      this.images = files[0];
      this.uploadMedia(position);
    },
    uploadMedia: async function (position) {
      this.$bus.$emit("show-loading", true);
      const formData = new FormData();
      formData.append("file", this.images);

      WalletRunRepository.uploadMedia(formData)
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
              this.configs[position].icon = response.data.data.link;
            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
    async loadGift() {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CampaignRepository.getGift();
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          let bannerInfo = JSON.parse(body.data.data.value);
          this.form.status = bannerInfo.status;
          this.configs = JSON.parse(bannerInfo.data_gift);
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    saveGift: async function () {

      this.form.data_gift = JSON.stringify(this.configs);
      let params = this.form;
      this.$bus.$emit("show-loading", true);
      CampaignRepository.storeGift(params)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: 'Cập nhật thông tin quà thành công',
            });
          }
        })
        .catch((response) => {
          this.$bus.$emit("show-loading", false);
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        status: '',
        data_gift: '',
      }
      this.configs = [
        {
          icon:'',
          title:'',
          desc:'',
          position:'',
        }
      ]

    },
  },
  created() {
    this.loadGift();
  },
};
</script>

<style scoped>
.title-block{
  color: #FF0000;
  font-size: 18px;
  font-weight: bold;
}
</style>
